<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :utvCheck="utvCheck"
          :attachInfo.sync="attachInfo"
          :imprTabInfo.sync="imprTabInfo"
          :isOld="isOld"
          :disabled="disabled"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingUtvCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingUtvCheckId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      utvCheck: {
        sopFireFightingUtvCheckId: '',  // UTV점검 일련번호
        plantCd: null,  // 사업장코드
        reportDate: '',  // 보고일시
        sopFireFightingUtvIds: '',  // UTV 소방차 (복수)
        contents: '',  // 내용
        insufficiencies: '',  // 미흡사항
        actionDetails: '',  // 조치내역
        useDt: '',  // 사용일시
        userId: '',  // 사용자
        usageHistory: '',  // 사용내역
        etc: '',  // 기타사항
        remarks: '',  // 비고
        utvCheckSrc: '',  // UTV소방창 점검사진
        completeFlag: 'N',  // 완료여부
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        results: [], // 점검결과
        deleteResults: [], // 점검결과
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'FIRE_UTV_CHECK',
        taskKey: '',
      },
      editable: false,
      detailUrl: '',
      listItemUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingUtvCheckId)
    },
    disabled() {
      return this.utvCheck.completeFlag === 'Y'
    },
    tabHeight() {
      let height = this.contentHeight - 36;
      if (height < 800) {
        height = 800;
      }
      return String(height);
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: 'LBLBASEINFO', component: () => import(`${'./fireFightingUtvCheckInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({
          name: 'improvement', icon: 'info', label: 'LBL0010138', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`)
        })
      }
      return _items;
    },
    imprTabInfo() {
      return {
        key: this.popupParam.sopFireFightingUtvCheckId, // 
        ibmTaskTypeCd: 'ITT0000215',
        ibmTaskUnderTypeCd: 'ITTU000245',
        requestContents: '(' + this.utvCheck.reportDate + ') ' + this.utvCheck.contents,
        disabled: false,
        isOld: true,
        isNotItemGrid: true,
        gridItem: {

        },
        gridOther: {
          title: '개선 목록',
        },
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingUtvCheck.get.url
      this.listItemUrl = selectConfig.fft.base.utvItem.list.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingUtvCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.utvCheck, _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.sopFireFightingUtvCheckId)
        },);
      } else {
        this.$set(this.utvCheck, 'userId', this.$store.getters.user.userId)
        // 초기 점검 항목
        this.$http.url = this.listItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.$store.getters.user.plantCd,
          useFlag: 'Y'
        };
        this.$http.request((_result) => {
          this.utvCheck.results = this.$_.map(_result.data, item => {
            return this.$_.extend(item, {
              sopFireFightingUtvCheckResultId: uid(),
              sopFireFightingUtvCheckId: '',
              resultContents: '',
              editFlag: 'C', 
              regUserId: this.$store.getters.user.userId,
            })
          });
        },);

      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>